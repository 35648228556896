import { observable } from "mobx";
import { createContext } from "react";
import { BRAND_STATUS, CHANNEL_REGISTER_MEMBER, URI } from "../../../constant";
import { globalService } from "../../../globalService";
import { logger } from "../../../helpers/logger";
import { stores } from "../../../store";

// declare const window: any;

export class RedirectStoreClass {

  @observable query = new URLSearchParams();
  // @observable isShowLoading = false;
  // @observable brandId = '';

  constructor() {
  }

  init = async () => {
    try {
      const type = this.query.get('type') || '';
      const brandId = this.query.get('id') || '';
      const ref = this.query.get('ref') || '';
      let isIgnoreOpenStore = false;
      // please check openMobileApp Function in GlobalService
      if (type == 'brandInfo') {
        const brand = await globalService.getCurrentBrand(brandId, true);
        if (brand?.status == BRAND_STATUS.VERIFIED && brand?.servicePackage?.member && brand?.channelRegisterMember == CHANNEL_REGISTER_MEMBER.WEB) {
          isIgnoreOpenStore = true;
          // this.isShowLoading = true;
          logger.debug('Navigate to Brand');
          stores.history.push({
            search: `id=${brand?.id}&forceUpdate=true&ref=${ref}`,
            pathname: URI.BRAND,
          });
        } else {
          // this.isShowLoading = false;
        }
      }
      // if (!isIgnoreOpenApp) {
      globalService.onClickDownload({ isIgnoreOpenStore, loading: false });
      // }

    } catch (err) {
      console.log('init redirect err: ', err);
    }

  }


}

export const RedirectStore = createContext(new RedirectStoreClass());
