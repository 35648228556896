import { computed, observable } from "mobx";
import { createContext } from "react";

export class VersionStoreClass {

  @observable query = new URLSearchParams();
  @observable t: any;

  constructor() {
  }

  init = async () => {

  }

}


export const VersionStore = createContext(new VersionStoreClass());
