import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './brandMember.scss';
import { useTranslation } from 'react-i18next';
import { BrandMemberStore, BrandMemberStoreClass } from './brandMemberStore';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { MemberType } from '../../../models/memberType';
import { BrandStore } from '../brand/brandStore';


let brandMemberStore: BrandMemberStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    brandMemberStore?.init();
  }

  render() {
    return this.props.children;
  }
}

Modal.setAppElement('#root');

const RenderBrandMember = () => {
  const { t } = useTranslation();
  brandMemberStore = useContext(BrandMemberStore);
  brandMemberStore.query = new URLSearchParams(useLocation().search);

  return (
    <div className="sub-container">
      <div className="brand-member-container pb-4">
        <div className="brand-member-banner mb-1 mx-auto" style={{ backgroundImage: `url(${require('../../../assets/imgs/not_register.png')})` }}>
        </div>
        <div className="text-center mb-3">
          <span className="clickable text-primary text-underline" onClick={() => brandMemberStore.onClickMembershipRegister()} > {t('register_now')}</span>
        </div>

        <div className="brand-body-container">
          <div className="brand-body-tab-container" >
            {
              (() => {
                let res: any[] = [];
                brandMemberStore.memberTypes?.forEach((mt: MemberType, index) => {
                  res.push(
                    <div className="rank-item"
                      onClick={() => brandMemberStore.onClickRankItem(mt)}
                      style={{ borderBottom: `2px solid ${brandMemberStore.selectedMemberType?.id == mt.id ? mt.color : 'transparent'}` }}
                      key={index}>
                      <div>
                        <div><i className="fa fa-shield rank-icon" style={{ color: mt.color }}></i></div>
                        <span>{mt.name}</span>
                      </div>
                    </div>
                  )
                })
                return res;
              })()
            }
          </div>
          <div className="brand-body-tab-content">
            <div dangerouslySetInnerHTML={{ __html: brandMemberStore.selectedMemberType.description }}>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

const ObserverBrandMember = observer(RenderBrandMember);
export const BrandMemberScreen = () => { return (<Wrapper> <ObserverBrandMember /> </Wrapper >) };
