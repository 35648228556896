import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './version.scss';
import { useTranslation } from 'react-i18next';
import { VersionStore, VersionStoreClass } from './versionStore';
import { Route, Switch, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import { appConfig } from '../../appConfig';

declare const window: any;

let versionStore: VersionStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    versionStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    overflow: 'auto',
    maxHeight: '100%',
  }
};
Modal.setAppElement('#root');

const RenderVersion = () => {
  const { t } = useTranslation();
  versionStore = useContext(VersionStore);
  versionStore.query = new URLSearchParams(useLocation().search);
  versionStore.t = t;

  return (
    <div className="sub-container p-3 text-center">
      <h4>LOYA ONE</h4>
      <small>Version {appConfig.config.appVersion}</small>
    </div >

  );
};

const ObserverVersion = observer(RenderVersion);
export const VersionScreen = () => { return (<Wrapper> <ObserverVersion /> </Wrapper >) };
