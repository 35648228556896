import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './redirect.scss';
import { useTranslation } from 'react-i18next';
import { RedirectStore, RedirectStoreClass } from './redirectStore';
import { Route, Switch, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import logoDownload from '../../assets/imgs/loyal_one@2x.png';
import PhoneInput from 'react-phone-input-2';
import { URI } from '../../../constant';

import { GlobalStore } from '../../../globalStore';

declare const window: any;

let redirectStore: RedirectStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    redirectStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    overflow: 'auto',
    maxHeight: '100%',
  }
};
Modal.setAppElement('#root');

const RenderRedirect = () => {
  const { t } = useTranslation();
  redirectStore = useContext(RedirectStore);
  redirectStore.query = new URLSearchParams(useLocation().search);

  return (
    <div>
      {/* {
        !redirectStore.isShowLoading ? */}
      <div className="info-container text-center">
        <div>
          <img width="120" src="https://loya.one/wp-content/uploads/2020/01/ic_launcher_round.png" />
        </div>
        <h3>Loya One</h3>
        <span>Đang chuyển tiếp đến ứng dụng....</span>
      </div> : null
      {/* } */}

    </div>
  );
};

const ObserverRedirect = observer(RenderRedirect);
export const RedirectScreen = () => { return (<Wrapper> <ObserverRedirect /> </Wrapper >) };
