
export const LOYA_NAME = 'Loya One';

export enum LOYA_SERVICE {
    LOYA_ONE = 'loya_one',
    LOYA_WORLD = 'loya_world',
}
export enum LOYA_URL_KEY {
    SERVICE = 'service',
    AT = 'at',
    SCREEN_URI = 'screen_uri',
}

export const VND = 'đ';

export const MONEY_UNITS = {
    PERCENT: 'PERCENT',
    VND: 'VND',
};
export enum CHANNEL_REGISTER_MEMBER {
    WEB = 'WEB',
    APP = 'APP',
}
export const URI = {
    LAYOUT: '/',
    BRAND: '/brand',
    BRAND_MEMBERSHIP_INFO: '/brand-member',
    MEMBERSHIP_REGISTER: '/member',
    REDIRECT: '/redirect',
    VERSION: '/version',
};

export const GENDER = {
    FEMALE: 1,
    MALE: 0,
    OTHER: 2,
};

export enum BRAND_STATUS {
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
    REJECTED = 'REJECTED', // not use now
    BLOCKED = 'BLOCKED',
}

export enum REGISTER_MEMBER_TYPES {
    NAME = 'NAME',
    PHONE = 'PHONE',
    ID_CARD = 'ID_CARD',
    BIRTHDAY = 'BIRTHDAY',
    GENDER = 'GENDER',
    EMAIL = 'EMAIL',
    ADDRESS = 'ADDRESS',
    NAME_REQUIRED = 'NAME_REQUIRED',
    PHONE_REQUIRED = 'PHONE_REQUIRED',
    ID_CARD_REQUIRED = 'ID_CARD_REQUIRED',
    BIRTHDAY_REQUIRED = 'BIRTHDAY_REQUIRED',
    GENDER_REQUIRED = 'GENDER_REQUIRED',
    EMAIL_REQUIRED = 'EMAIL_REQUIRED',
    ADDRESS_REQUIRED = 'ADDRESS_REQUIRED',
}

export const LOGIN_TYPE = {
    MERCHANT: 'MERCHANT',
};

export const LOYA_ONE = 'Loya One';

export const STATUS_CODE = {
    SUCCESS: 991,
    CREATED: 992,
    NOT_FOUND: 994,
    USER_INVALID: 901,
    ERROR_UNDEFINED: 900,
    WRONG_BRAND_CODE: 903,
    PASSWORD_INVALID: 904,
    ID_INVALID: 906,
    USER_IS_EXISTED: 907,
    DATA_IS_EXIST: 950,
    INVALID_STATUS: 954,
    USER_INACTIVE: 908,
    DATA_IS_INVALID: 951,
    NOT_POSITIVE_NUMBER: 953,
    NOT_ENABLE: 993,
    DUPLICATE_INDEX: 952,
};
export const SOCIAL_METHOD = {
    PHONE: 'phone',
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
};

export const SOURCE_TYPE = {
    GIFT: 'gift',
    SHARE: 'share',
};

export const EVENT_EMITTER = {
    TOASTR: 'TOASTR',
    LOADING_ALL: 'LOADING_ALL',
}
export const ACTION_TYPE = {
    POINT: 'point',
    VOUCHER: 'voucher',
};
export const DEVICE = {
    Android: 'Android',
    iOS: 'iOS',
    webOS: 'webOS',
    iPhone: 'iPhone',
    iPod: 'iPod',
    BlackBerry: 'BlackBerry',
    WindowsPhone: 'WindowsPhone',
    unknown: 'unknown',
};

export const MIN_PASSWORD_LENGTH = 6;
export const zeroUID = '00000000-0000-0000-0000-000000000000';
