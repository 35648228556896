import { observable } from "mobx";
import { createContext } from "react";
import { Brand } from "../../models/brand";
import { globalService } from "../../globalService";
declare const window: any;

export class LayoutStoreClass {

  @observable history: any;
  @observable brand: Brand = {} as any;
  @observable brandId = '';
  @observable query = new URLSearchParams();
  @observable isShowLoading = false;

  @observable type = '';

  constructor() {
  }

  init = async () => {
    // setInterval(() => {
    //   const s = window.open("loyal-one-biz://");

    //   const y = location.href = 'loyal-one-biz://';
    //   console.log(s, y);
    // }, 1000)
    // document.location.href = 
    // (alert as any) = function () { alert(1) };
    // (prompt as any) = function () { alert(2) };
    // (confirm as any) = function () { alert(3) };
    // document.ontouchstart = function() {
    //   alert('ouched');
    // } 
    document.ontouchmove = function (event) {
      event.preventDefault();
    }
  }

  onClickDownload = async () => {
    globalService.onClickDownload({ isIgnoreOpenStore: false, loading: true });
  }

}

export const LayoutStore = createContext(new LayoutStoreClass());
