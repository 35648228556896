import { alertHelper } from "./toastHelper";
import { logger } from "./logger";
import { DEVICE } from "../constant";

class Helper {

    processCommonError = (res: any) => {
        logger.error('Process common error', res);
        // alertHelper.error('something_went_wrong');
    }

    getUrlParams = (key: string): string => {
        var url = new URL(location.href);
        var c = url.searchParams.get(key);
        return c?.toString() || '';
    }

    convertToQueriesParams = (paramsIn: Object) => {
        const params: any = Object.assign({}, paramsIn);
        let paramUri = '';
        if (params) {
            paramUri = '?';
            const arr = Object.keys(params);
            arr.forEach((item: any) => {
                if (item === 'keyword' || item === 'phone') {
                    let data = encodeURIComponent(params[item]);
                    if (data.charAt(0) === '0') {
                        data = data.substring(1, data.length);
                    }
                    paramUri = paramUri + item + '=' + data + '&';
                } else {
                    if (Array.isArray(params[item])) {
                        params[item].forEach((data: string) => {
                            paramUri = paramUri + item + '=' + encodeURIComponent(data) + '&';
                        });
                    } else {
                        paramUri = paramUri + item + '=' + encodeURIComponent(params[item]) + '&';
                    }
                }
            });
        }
        return paramUri;
    }

    processPhone(phone: string) {
        if (phone) {
            console.log(phone);
            try {
                const phoneCodes = [
                    '+84',
                ];
                let phoneCode = phone.substr(0, 3);
                let firstPhoneCharacter = phone.substr(3, 1);
                console.log(firstPhoneCharacter);
                if (phoneCodes.includes(phoneCode)) {
                    if (firstPhoneCharacter == '0') {
                        phone = this.removeChartAt(phone, 3);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
        return phone;
    }

    removeChartAt(txt: string, i: number) {
        var tmp = txt.split(''); // convert to an array
        tmp.splice(i, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }

    getDevice() {
        let userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/android/i.test(userAgent)) {
            return DEVICE.Android;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return DEVICE.iOS;
        }
        return DEVICE.unknown;
    }

    convertFullPathToQuery(fullPath: string) {
        // the fullPath has a format: '/?a=1&b=2&c=3';
        fullPath = fullPath.replace('/?', '');
        var pairs = fullPath.split('&');
        var query: any = {};
        pairs.forEach(pair => {
            var p = pair.split('=');
            query[p[0]] = p[1];
        });
        return query;
    }
    /**
     * 
     * @param object : example {
        parameter1: 'value_1',
        parameter2: 'value 2',
        parameter3: 'value&3' 
        }
    * The result: "parameter1=value_1&parameter2=value%202&parameter3=value%263"
    * 
     */

    objectToQueryParams(object: Object) {
        const esc = encodeURIComponent;
        const query = Object.keys(object)
            .map(k => `${esc(k)}=${esc((object as any)[k])}`)
            .join('&');
        return query;

    }
    copyToClipboardByText(text: string) {
        const dummy = document.createElement("textarea");
        // to avoid breaking orgain page when copying more words
        // cant copy when adding below this code
        // dummy.style.display = 'none'
        document.body.appendChild(dummy);
        //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }
    getPercentNumber(value: any, digits?: string): string {
        const decimal = 2;
        const unit = '%';
        let number = (value * 100).toFixed(decimal);
        while ((number.charAt(number.length - 1) === '0' || number.charAt(number.length - 1) === '.') && number.includes('.')) {
            number = number.slice(0, -1);
        }
        value = number;
        const array = (value + '').split('.');
        let decimalTxt = '';
        if (array.length >= 2) {
            value = parseInt(array[0], 10);
            decimalTxt = '.' + array[1];
        }
        console.log(value, decimalTxt);
        return value + decimalTxt + '' + unit;
        // if (super.transform(value, digits) !== null && super.transform(value, digits) !== undefined) {
        //     return super.transform(value, digits).replace(/\./g, ', ') + decimalTxt + '' + unit;
        // }
    }
}

export const helper = new Helper();

