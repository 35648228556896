import moment from 'moment';

class TimeHelper {

    constructor() { }

    convertTimestampToDayMonthYearHourMinute(milliseconds: number) {
        const date = moment.unix(milliseconds / 1000).format('DD/MM/YYYY');
        const time = moment.unix(milliseconds / 1000).format('HH:mm:ss');
        return date + ' - ' + time;
    }

    convertTimestampToDayMonthYear(milliseconds: number) {
        const date = moment.unix(milliseconds / 1000).format('DD/MM/YYYY');
        return date;
    }

    /**
     *  for show date on UI
     * @param miliSecond 
     * @param showTime 
     * @param endTime 
     * @Return Date string
     */
    convertToDateTime(miliSecond: number, showTime = true, endTime = false) {

        if (!miliSecond) {
            return '';
        }
        const date = new Date(miliSecond);

        const now = new Date(2013, 11, 31);
        let str = now.toLocaleDateString();
        str = str.replace('31', 'dd');
        str = str.replace('12', 'mm');
        str = str.replace('2013', 'yyyy');

        // let newDate = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear()
        //     + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);

        // if (str === 'dd/mm/yyyy') {
        let newDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
            ;
        // }

        if (showTime) {
            if (!endTime) {
                newDate = newDate + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
            } else {
                newDate = newDate + ' 23:59';
            }
        }

        return newDate || '';

    }

    convertDateToDayMonthYear(d: Date) {
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return `${day}/${month}/${year}`;
    }

    getNextMonth(_day: any, isEndTime?: boolean) {
        let day = new Date(_day);
        day.setDate(day.getDate() + 29);
        if (isEndTime) {
            day.setHours(23, 59, 59, 999);
        } else {
            day.setHours(0, 0, 0, 0);
            const timezone = this.getCurrentTimezone();
            let mili = day.getTime();
            mili += timezone * 1000 * 60 * 60;
            day = new Date(mili);
        }
        return new Date(day);
    }

    getCurrentTimezone() {
        return -(new Date().getTimezoneOffset() / 60);
    }

    convertTimestampToMinute(milliseconds: number) {
        return Math.round(milliseconds / 1000 / 60);
    }

    differentMillisecondToHourMinute(milliseconds: number) {
        const minutes = Math.floor(milliseconds / (1000 * 60));
        const h = Math.floor(minutes / 60);
        const m = minutes - h * 60;
        if (h < 0) {
            return '0:00';
        } else {
            return m < 10 ? `${h}:0${m}` : `${h}:${m}`;
        }

    }

}

export const timeHelper = new TimeHelper();