import { BrandStatus } from "../models/brand";
import { HttpPagingListResponse } from "../models/httpListResponse";
import { MemberType } from "../models/memberType";
import { DistrictModel, ProvinceModel } from "../models/province";
import { Voucher } from "../models/voucher";
import { http } from "./http";

class LoyaltyApi {

    async getBrandVouchers(brandId?: string, offset?: number, limit?: number, keyword?: string, categoryId?: string, statuses?: BrandStatus[]): Promise<HttpPagingListResponse<Voucher>> {
        try {
            const header = await http.getAuthorizedHeader();
            let params = {} as any;

            if (brandId !== undefined) {
                params.brandId = brandId;
            }

            if (keyword !== undefined) {
                params.keyword = keyword;
            }

            if (categoryId !== undefined) {
                params.categoryId = categoryId;
            }

            if (offset !== undefined) {
                params.offset = offset
            }

            if (limit !== undefined) {
                params.limit = limit;
            }

            let uri = `loyalty/voucher?${http.objectToQueryParams(params)}`;

            if (statuses && statuses?.length > 0) {
                statuses.forEach(status => {
                    uri += `&status=${status}`;
                })
            }

            const response = await http.get(uri, header);
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getMemberTypes(brandId?: string): Promise<HttpPagingListResponse<MemberType>> {
        try {
            const header = await http.getAuthorizedHeader();
            let params = {} as any;

            if (brandId) {
                params.brandId = brandId
            }
            const uri = `loyalty/memberType?${http.objectToQueryParams(params)}`;
            const response = await http.get(uri, header);
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getProvinces(): Promise<HttpPagingListResponse<ProvinceModel>> {
        try {
            const header = await http.getAuthorizedHeader();
            let params = {} as any;
                params.nationCode = 'VN';
            const uri = `/utility/province?${http.objectToQueryParams(params)}`;
            const response = await http.get(uri, header);
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getDistrictsByProvince(provinceId: string): Promise<HttpPagingListResponse<DistrictModel>> {
        try {
            const header = await http.getAuthorizedHeader();
            let params = {} as any;
            if (provinceId) {
                params.provinceId = provinceId;
            }
            const uri = `/utility/district?${http.objectToQueryParams(params)}`;
            const response = await http.get(uri, header);
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async addDeeplinkDataForUserApp(params: any): Promise<HttpPagingListResponse<MemberType>> {
        try {
            const header = await http.getAuthorizedHeader();
            const uri = `utility/utils/deepLink`;
            const response = await http.post(uri, params, header);
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

}
export const loyaltyApi = new LoyaltyApi();