export default {
    membership_register: 'Đăng ký thành viên',
    request_register: 'Gửi yêu cầu',
    required: 'Bắt buộc',
    name: 'Tên',
    phone: 'SĐT',
    email: 'Email',
    address: 'Địa chỉ',
    date_of_birth: 'Ngày sinh',
    gender: 'Giới tính',
    term_and_condition: 'Điều khoản thành viên',
    male: 'Nam',
    female: 'Nữ',
    other: 'Khác',
    i_have_read_and_agree: 'Tôi đã đọc và đồng ý với điều khoản của thương hiệu.',
    registration_information: 'Thông tin đăng kí này cũng đồng thời được cập nhật qua thông tin tài khoản Loya One của bạn.',
    successfully_request_register: 'Gửi yêu cầu thành công',
    id_card: 'CMND',
    download_app: 'Tải ứng dụng',
    download_app_description: 'Tích điểm, quản lý hạng thành viên và nhận nhiều ưu đãi từ thương hiệu.',
    register_phone_was_existed: 'Số điện thoại đăng ký đã tồn tại',
    an_error_occur: 'Đã có lỗi xảy ra',
    brand_do_not_support: 'Thương hiệu hiện tại không hỗ trợ yêu cầu đăng kí thành viên.',
    registered_membership_required: 'Số điện thoại này đã yêu cầu đăng kí thành viên',
    enter_your_name: 'Nhập tên',
    choose_gender: 'Chọn giới tính',
    invalid_email: 'Sai định dạng email',
    enter_your_email: 'Nhập email',
    enter_your_address: 'Nhập địa chỉ',
    enter_your_id_card: 'Nhập CMND',
    the_brand_currently_does_not_support_membership_register: 'Thương hiệu hiện tại không hỗ trợ yêu cầu đăng ký thành viên',
    brand_does_not_enable_this_feature: 'Thương hiệu chưa đăng ký sử dụng tính năng này',
    system_error: 'Hệ thống đang bảo trì',
    error_undefined: 'Đã có lỗi xảy ra',
    membership_card: 'Thẻ thành viên',
    voucher: 'Ưu đãi',
    from_date: 'Từ',
    to_date: 'Đến',
    view_more: 'Xem thêm',
    collapse: 'Thu gọn',
    view_all: 'Xem tất cả',
    register_now: 'Đăng ký ngay',
    free: 'Miễn phí',
    point: 'Điểm',
    referral_code: 'Mã giới thiệu',
    enter_your_referral_code: 'Nhập mã giới thiệu',
    referral_code_was_not_exist: 'Mã giới thiệu không tồn tại',
    select_date: 'Chọn ngày',
    cancel: 'Hủy',
    confirm: 'Xác nhận',
    province: 'Tỉnh/TP',
    select_province: 'Chọn Tỉnh/TP',
    district: 'Quận/Huyện',
    select_district: 'Chọn Quận/Huyện',
    cannot_enter_your_own_code: 'Không thể nhập mã của chính bạn',
}