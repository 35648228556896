import { observable } from "mobx";
import { createContext } from "react";
import { authApi } from "../../../api/auth.api";
import { loyaltyApi } from "../../../api/loyalty.api";
import { appConfig, config } from "../../../appConfig";
import { BRAND_STATUS, DEVICE, EVENT_EMITTER, GENDER, LOYA_ONE, REGISTER_MEMBER_TYPES, SOURCE_TYPE, STATUS_CODE } from "../../../constant";
import { globalEvent } from "../../../helpers/globalEvents";
import { helper } from "../../../helpers/helper";
import { logger } from "../../../helpers/logger";
import { alertHelper } from "../../../helpers/toastHelper";
import { Brand } from "../../../models/brand";
import { DistrictModel, ProvinceModel } from "../../../models/province";
const Rolldate: any = require('../../../js/rolldate.min.js');
const $: any = require('jquery');
declare const window: any;

export class MembershipRegisterStoreClass {

  @observable history: any;
  @observable brand: Brand = {} as any;
  @observable brandId = '';
  @observable ref = '';
  @observable query = new URLSearchParams();
  @observable t: any;
  location = {} as any;
  @observable name = '';
  @observable phone = '';
  @observable idCard = '';
  @observable dateOfBirth: any;
  @observable selectGenderValue = '';
  @observable email = '';
  @observable address = '';
  @observable presenterCode = '';
  @observable conditionOk = true;
  @observable isShowLoading = false;
  @observable isOpenModal: boolean = false;
  @observable checkEmail: boolean = false;
  MIN_PASSWORD_LENGTH = 1;
  GENDER = GENDER;
  LOYA_ONE = LOYA_ONE;

  @observable isName = false;
  @observable isPhone = false;
  @observable isIdCard = false;
  @observable isDateOfBirth = false;
  @observable isGender = false;
  @observable isEmail = false;
  @observable isAddress = false;

  @observable isNameRequire = false;
  @observable isPhoneRequire = false;
  @observable isIdCardRequire = false;
  @observable isDateOfBirthRequire = false;
  @observable isGenderRequire = false;
  @observable isEmailRequire = false;
  @observable isAddressRequire = false;

  @observable selectedProvince = '';
  @observable selectedDistrict = '';
  @observable provinces: ProvinceModel[] = [];
  @observable districts: DistrictModel[] = [];

  @observable type = '';

  constructor() {
  }

  init = async () => {
    if (this.query.get('id')) {
      this.brandId = this.query.get('id') || '';
    }
    if (this.query.get('ref')) {
      this.ref = this.query.get('ref') || '';
      this.presenterCode = this.ref;
    }
    this.type = this.query.get('type') || '';
    this.brand = await this.getBrandById();
    if (this.brand && this.brand.registerMemberTypes && this.brand.registerMemberTypes.length > 0) {
      this.brand.registerMemberTypes.forEach((item: any) => {
        if (item == REGISTER_MEMBER_TYPES.NAME) {
          this.isName = true;
        } else if (item == REGISTER_MEMBER_TYPES.ADDRESS) {
          this.isAddress = true;
        } else if (item == REGISTER_MEMBER_TYPES.BIRTHDAY) {
          this.isDateOfBirth = true;
        } else if (item == REGISTER_MEMBER_TYPES.EMAIL) {
          this.isEmail = true;
        } else if (item == REGISTER_MEMBER_TYPES.GENDER) {
          this.isGender = true;
        } else if (item == REGISTER_MEMBER_TYPES.ID_CARD) {
          this.isIdCard = true;
        } else if (item == REGISTER_MEMBER_TYPES.PHONE) {
          this.isPhone = true;
        } else if (item == REGISTER_MEMBER_TYPES.NAME_REQUIRED) {
          this.isNameRequire = true;
        } else if (item == REGISTER_MEMBER_TYPES.ADDRESS_REQUIRED) {
          this.isAddressRequire = true;
        } else if (item == REGISTER_MEMBER_TYPES.BIRTHDAY_REQUIRED) {
          this.isDateOfBirthRequire = true;
        } else if (item == REGISTER_MEMBER_TYPES.EMAIL_REQUIRED) {
          this.isEmailRequire = true;
        } else if (item == REGISTER_MEMBER_TYPES.GENDER_REQUIRED) {
          this.isGenderRequire = true;
        } else if (item == REGISTER_MEMBER_TYPES.ID_CARD_REQUIRED) {
          this.isIdCardRequire = true;
        } else if (item == REGISTER_MEMBER_TYPES.PHONE_REQUIRED) {
          this.isPhoneRequire = true;
        }

      });
    }
    this.getProvinces();
    if (this.brand && this.brand.status == BRAND_STATUS.VERIFIED && this.brand.servicePackage && this.brand.servicePackage.member) {
      this.isShowLoading = true;
    } else {
      this.isShowLoading = false;
    }


    const birthday = new Rolldate({
      el: '#updateMemebershipRegisterDate',
      format: 'DD/MM/YYYY', // make sure check confirm Funtion before edited format here
      beginYear: 1900,
      endYear: new Date().getFullYear(),
      lang: {
        title: this.t('select_date'),
        cancel: this.t('cancel'),
        confirm: this.t('confirm'),
        year: '',
        month: '',
        day: '',
        hour: '',
        min: '',
        sec: ''
      },
      confirm: (date: any) => {
        // date is string like format
        const arr = date.split('/');
        const day = arr[0];
        const month = arr[1];
        const year = arr[2];
        this.dateOfBirth = new Date(year, month - 1, day);
      },
    })

  }

  getBrandById = async () => {
    let brand;
    try {
      const res = await authApi.getBrandById(this.brandId);
      if (res.status_code === STATUS_CODE.SUCCESS) {
        brand = res.data;
        console.log(this.brand);
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    }
    return brand;
  }

  refreshTextDate = async (event: any) => {
    event.preventDefault();
    this.dateOfBirth = '';
    $('#updateMemebershipRegisterDate').val('');
  }

  onSubmitRegisterMember = async (event: any) => {
    event.preventDefault();
    try {
      globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, true);
      let date;
      let gender;
      if (this.dateOfBirth) {
        date = new Date(this.dateOfBirth).valueOf()
      }
      if (this.phone && this.phone.charAt(0) != '+') {
        this.phone = this.phone.replace('', '+');
      }
      if (this.phone.includes('+84')) {
        this.phone = helper.processPhone(this.phone);
      }
      if (this.selectGenderValue) {
        gender = parseInt(this.selectGenderValue);
      }
      const model = {
        brandId: this.brandId,
        phone: this.phone,
        name: this.name,
        address: this.address,
        email: this.email,
        dateOfBirth: date,
        gender: gender,
        idCard: this.idCard,
        presenterCode: this.presenterCode,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
      };
      const res = await authApi.registerMembership(model);
      if (res.status_code === STATUS_CODE.SUCCESS || res.status_code === STATUS_CODE.CREATED) {
        alertHelper.success('successfully_request_register');
        this.name = '';
        this.phone = '+84';
        this.idCard = '';
        this.dateOfBirth = '';
        $('#updateMemebershipRegisterDate').val('');
        this.selectGenderValue = '';
        this.email = '';
        this.address = '';
        this.presenterCode = '';
        this.conditionOk = true;
        this.selectedProvince = '';
        this.selectedDistrict = '';
        this.districts = [];
      } else if (res.status_code === STATUS_CODE.USER_IS_EXISTED) {
        alertHelper.warning('register_phone_was_existed');
      } else if (res.status_code === STATUS_CODE.NOT_FOUND) {
        alertHelper.warning('referral_code_was_not_exist');
      } else if (res.status_code === STATUS_CODE.DUPLICATE_INDEX) {
        alertHelper.warning('cannot_enter_your_own_code');
      } else if (res.status_code === STATUS_CODE.INVALID_STATUS) {
        alertHelper.warning('brand_do_not_support');
      } else if (res.status_code === STATUS_CODE.DATA_IS_EXIST) {
        alertHelper.warning('registered_membership_required');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    } finally {
      globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, false);
    }

  }

  nameChange = (text: string) => {
    this.name = text;
  }

  phoneChange = (text: string) => {
    this.phone = text;
  }

  dateOfBirthChange = (text: Date) => {
    this.dateOfBirth = text;
    console.log(this.dateOfBirth)
  }
  dateOfBirthChangeRaw = (e: any) => {
    e.preventDefault();
  }

  selectGenderChange = (text: string) => {
    this.selectGenderValue = text;
    console.log(text)
  }

  emailChange = (text: string) => {
    this.email = text;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(this.email)) {
      this.checkEmail = true;
    } else {
      this.checkEmail = false;
    }
  }

  addressChange = (text: string) => {
    this.address = text;
  }

  presenterCodeChange = (text: string) => {
    this.presenterCode = text;
  }

  idCardChange = (text: string) => {
    this.idCard = text;
  }

  conditionOkChange = () => {
    this.conditionOk = !this.conditionOk;
  }

  closeModal = () => {
    this.isOpenModal = false;
  }

  onClickShowCondition = () => {
    this.isOpenModal = true;
  }

  createMarkup() {
    let inner = '';
    if (this.brand && this.brand.policy) {
      inner = this.brand.policy;
    }
    return {
      __html: inner
    };
  };

  onBlur = () => {

  }

  getProvinces = async () => {
    globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, true);
    try {

      const response = await loyaltyApi.getProvinces();
      if (response?.status_code === STATUS_CODE.SUCCESS) {
        this.provinces = response.data?.items;
      } else {
        throw response;
      }
      console.log("Province -> response", response)
    } catch (err) {
      helper.processCommonError(err);
    }
    globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, false);
  }

  onChangeProvince = (text: string) => {
    this.selectedProvince = text;
    if (this.selectedProvince) {
      this.getDistrictsByProvince(this.selectedProvince);
    } else {
      this.districts = [];
      this.selectedDistrict = '';
    }
  }

  onChangeDistrict = (text: string) => {
    this.selectedDistrict = text;
  }

  getDistrictsByProvince = async (provinceId: string) => {
    globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, true);
    try {

      const response = await loyaltyApi.getDistrictsByProvince(provinceId);
      if (response?.status_code === STATUS_CODE.SUCCESS) {
        this.districts = response.data?.items;
      } else {
        throw response;
      }
      console.log("District -> response", response)
    } catch (err) {
      helper.processCommonError(err);
    }
    globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, false);
  }

}

export const MembershipRegisterStore = createContext(new MembershipRegisterStoreClass());
