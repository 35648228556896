import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './membershipRegister.scss';
import { useTranslation } from 'react-i18next';
import { MembershipRegisterStore, MembershipRegisterStoreClass } from './membershipRegisterStore';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import logoDownload from '../../../assets/imgs/loyal_one@2x.png';
import PhoneInput from 'react-phone-input-2';
import { DistrictModel, ProvinceModel } from '../../../models/province';

declare const window: any;

let membershipRegisterStore: MembershipRegisterStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    membershipRegisterStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    overflow: 'auto',
    maxHeight: '100%',
  }
};
Modal.setAppElement('#root');

const RenderMembershipRegister = () => {
  const { t } = useTranslation();
  membershipRegisterStore = useContext(MembershipRegisterStore);
  membershipRegisterStore.query = new URLSearchParams(useLocation().search);
  membershipRegisterStore.t = t;
  return (
    <div >
      <div>

        {membershipRegisterStore.isShowLoading
          ? <div className="modal-container card m-0">

            <div className="mt-2">
              <div className="">
                <div className="row mt-1">
                  <div className="col-12 text-center">
                    <img className="modal-img" src={membershipRegisterStore.brand.urlAvatar} />
                  </div>
                </div>
                <div className="brand-name"><span className="brand-text">{membershipRegisterStore.brand.name}</span></div>
              </div>
              {
                membershipRegisterStore.brand?.registerMemberTypes?.length > 0
                  ?
                  <form autoComplete="off" >
                    <div className="modal-body padding-top-title">
                      <div className="row justify-content-center">
                        <span className="title-member">{t('membership_register')}</span>
                      </div>
                      {(membershipRegisterStore.isName || membershipRegisterStore.isNameRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('name')}: {membershipRegisterStore.isNameRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
                              </div>
                              <input type="text" value={membershipRegisterStore.name} name="name" className="form-control" placeholder={t('enter_your_name')}
                                onChange={(e: any) => membershipRegisterStore.nameChange(e.target.value)} />

                            </div>
                            {/* {
                              membershipRegisterStore.isNameRequire && !membershipRegisterStore.name && <span className='text-error ml-3' >
                                {t('required')}
                              </span>
                            } */}
                          </div>
                        </div>
                      }

                      {(membershipRegisterStore.isPhone || membershipRegisterStore.isPhoneRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 none-padding row-padding-right">
                            <label>{t('phone')}: {membershipRegisterStore.isPhoneRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <PhoneInput
                              inputClass="form-control"
                              country={'vn'}
                              placeholder="123 456 789"
                              countryCodeEditable={false}
                              value={membershipRegisterStore.phone}
                              onChange={(e: any) => membershipRegisterStore.phoneChange(e)}
                            />
                            {/* {
                              membershipRegisterStore.isPhoneRequire && !membershipRegisterStore.phone && <span className='text-error ml-3' >
                                {t('required')}
                              </span>
                            } */}
                          </div>
                        </div>
                      }
                      {(membershipRegisterStore.isIdCard || membershipRegisterStore.isIdCardRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('id_card')}: {membershipRegisterStore.isIdCardRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-id-card-o" aria-hidden="true"></i></span>
                              </div>
                              <input type="text" value={membershipRegisterStore.idCard} name="idCard" className="form-control" placeholder={t('enter_your_id_card')}
                                onChange={(e: any) => membershipRegisterStore.idCardChange(e.target.value)} />

                            </div>
                            {/* {
                              membershipRegisterStore.isIdCardRequire && !membershipRegisterStore.idCard && <span className='text-error ml-3' >
                                {t('required')}
                              </span>
                            } */}
                          </div>
                        </div>
                      }
                      {(membershipRegisterStore.isDateOfBirth || membershipRegisterStore.isDateOfBirthRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('date_of_birth')}: {membershipRegisterStore.isDateOfBirthRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group date-hide-cursor">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-calendar" aria-hidden="true"></i></span>
                              </div>
                              <input className="form-control" type="text"
                                id="updateMemebershipRegisterDate" placeholder="DD/MM/YYYY" />
                              {/* <input type="date" value={membershipRegisterStore.dateOfBirth} name="date" className="form-control"
                                onChange={(e: any) => membershipRegisterStore.dateOfBirthChange(e.target.value)} /> */}
                              {
                                membershipRegisterStore.dateOfBirth &&
                                <i className="fa fa-times-circle icon" aria-hidden="true" onClick={membershipRegisterStore.refreshTextDate}></i>
                              }
                            </div>
                          </div>
                        </div>
                      }
                      {(membershipRegisterStore.isGender || membershipRegisterStore.isGenderRequire) &&

                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('gender')}: {membershipRegisterStore.isGenderRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
                              </div>
                              <select
                                className="form-control"
                                value={membershipRegisterStore.selectGenderValue}
                                onChange={(e: any) => membershipRegisterStore.selectGenderChange(e.target.value)}
                              >
                                <option value="">{t('choose_gender')}</option>
                                <option value={membershipRegisterStore.GENDER.MALE}>{t('male')}</option>
                                <option value={membershipRegisterStore.GENDER.FEMALE}>{t('female')}</option>
                                <option value={membershipRegisterStore.GENDER.OTHER}>{t('other')}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      }
                      {(membershipRegisterStore.isEmail || membershipRegisterStore.isEmailRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('email')}: {membershipRegisterStore.isEmailRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-envelope-o" aria-hidden="true"></i></span>
                              </div>
                              <input type="email" value={membershipRegisterStore.email} name="email" className="form-control" placeholder={t('enter_your_email')}
                                onChange={(e: any) => membershipRegisterStore.emailChange(e.target.value)} />
                            </div>
                            {
                              membershipRegisterStore.email.length > 0 && !membershipRegisterStore.checkEmail && <span className='text-error ml-3' >
                                {t('invalid_email')}
                              </span>
                            }
                          </div>
                        </div>

                      }
                      {(membershipRegisterStore.isAddress || membershipRegisterStore.isAddressRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('province')}: {membershipRegisterStore.isAddressRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-map-marker"></i></span>
                              </div>
                              <select
                                className="form-control"
                                value={membershipRegisterStore.selectedProvince}
                                onChange={(e: any) => membershipRegisterStore.onChangeProvince(e.target.value)}
                              >
                                <option value="">{t('select_province')}</option>
                                {
                                  (() => {
                                    let res: any[] = [];
                                    membershipRegisterStore.provinces?.forEach((pro: ProvinceModel, i) => {
                                      res.push(
                                        <option value={pro.id} key={i}>{pro.name}</option>
                                      )
                                    })
                                    return res;
                                  })()
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      }
                      {(membershipRegisterStore.isAddress || membershipRegisterStore.isAddressRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('district')}: {membershipRegisterStore.isAddressRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-map-marker"></i></span>
                              </div>
                              <select
                                className="form-control"
                                value={membershipRegisterStore.selectedDistrict}
                                onChange={(e: any) => membershipRegisterStore.onChangeDistrict(e.target.value)}
                              >
                                <option value="">{t('select_district')}</option>
                                {
                                  (() => {
                                    let res: any[] = [];
                                    membershipRegisterStore.districts?.forEach((dis: DistrictModel, i) => {
                                      res.push(
                                        <option value={dis.id} key={i}>{dis.name}</option>
                                      )
                                    })
                                    return res;
                                  })()
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      }
                      {(membershipRegisterStore.isAddress || membershipRegisterStore.isAddressRequire) &&
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('address')}: {membershipRegisterStore.isAddressRequire && <i className="text-danger">*</i>}</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-address-card-o" aria-hidden="true"></i></span>
                              </div>
                              <input type="text" value={membershipRegisterStore.address} name="address" className="form-control" placeholder={t('enter_your_address')}
                                onChange={(e: any) => membershipRegisterStore.addressChange(e.target.value)} />
                            </div>
                          </div>
                        </div>
                      }
                      {
                        <div className="row mb-2 justify-content-center">
                          <div className="col-3 col-md-3 col-sm-3 row-padding-right">
                            <label>{t('referral_code')}:</label>
                          </div>
                          <div className="col-9 col-md-6 col-sm-6 row-padding-left">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-gift" aria-hidden="true"></i></span>
                              </div>
                              <input type="text" value={membershipRegisterStore.presenterCode} name="presenterCode" className="form-control" placeholder={t('enter_your_referral_code')}
                                onChange={(e: any) => membershipRegisterStore.presenterCodeChange(e.target.value)} />
                            </div>
                          </div>
                        </div>
                      }
                      {membershipRegisterStore.brand && membershipRegisterStore.brand.policy &&
                        <div>
                          <div className="row mb-2 justify-content-center">
                            <div className="col-12 text-center">
                              <span className="text-click"
                                onClick={membershipRegisterStore.onClickShowCondition}>{t('term_and_condition')}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-9 offset-md-3">
                              <input type="checkbox" checked={membershipRegisterStore.conditionOk} name="conditionOk" className="checkbox-info"
                                onChange={() => membershipRegisterStore.conditionOkChange()} /><span>{t('i_have_read_and_agree')}</span>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="modal-footer justify-content-center">
                        <button type='button' className='btn btn-primary' onClick={membershipRegisterStore.onSubmitRegisterMember}
                          disabled={
                            !membershipRegisterStore.conditionOk
                            || membershipRegisterStore.isAddressRequire && !membershipRegisterStore.address
                            || membershipRegisterStore.isAddressRequire && !membershipRegisterStore.selectedProvince
                            || membershipRegisterStore.isAddressRequire && !membershipRegisterStore.selectedDistrict
                            || membershipRegisterStore.isEmailRequire && !membershipRegisterStore.email
                            || membershipRegisterStore.isGenderRequire && !membershipRegisterStore.selectGenderValue
                            || membershipRegisterStore.isDateOfBirthRequire && !membershipRegisterStore.dateOfBirth
                            || membershipRegisterStore.isIdCardRequire && !membershipRegisterStore.idCard
                            || membershipRegisterStore.isPhoneRequire && membershipRegisterStore.phone.length < 3
                            || membershipRegisterStore.isNameRequire && !membershipRegisterStore.name
                            || membershipRegisterStore.email.length > 0 && !membershipRegisterStore.checkEmail
                          }
                        >{t('request_register')}</button>
                      </div>
                    </div>
                  </form>
                  :
                  <div className="modal-body padding-top-title">
                    <div className="row justify-content-center">
                      <span className="title-member">{t('membership_register')}</span>
                    </div>
                    <h6 className="text-center m-4">{t('the_brand_currently_does_not_support_membership_register')}</h6>
                  </div>
              }
            </div>


          </div>
          : null
        }


      </div >
      <Modal
        isOpen={membershipRegisterStore.isOpenModal}
        onRequestClose={membershipRegisterStore.closeModal}
        style={customStyles}
      >
        <div className="modal-container">
          <div className="header-title">
            <span className="header-content">{t('term_and_condition')}</span>
            <span className="del-modal" onClick={membershipRegisterStore.closeModal}><i className="fa fa-times"></i></span>
          </div>
          <hr />
          <div className="body-modal" dangerouslySetInnerHTML={membershipRegisterStore.createMarkup()} />
        </div>
      </Modal>
    </div>
  );
};

const ObserverMembershipRegister = observer(RenderMembershipRegister);
export const MembershipRegisterScreen = () => { return (<Wrapper> <ObserverMembershipRegister /> </Wrapper >) };
