export default {
    membership_register: 'Membership register',
    request_register: 'Request register',
    required: 'Required',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    date_of_birth: 'Date of birth',
    gender: 'Gender',
    term_and_condition: 'Term and condition',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    i_have_read_and_agree: 'I have read and agree to the terms of the brand.',
    registration_information: 'This registration information is also updated via your Loya One account.',
    successfully_request_register: 'Successfully request register',
    id_card: 'Id card',
    download_app: 'Download app',
    download_app_description: 'Earn point, membership info and receive more voucher from store.',
    register_phone_was_existed: 'Phone number was existed',
    an_error_occur: 'An error occur',
    brand_do_not_support: 'Current brands do not support membership registration.',
    registered_membership_required: 'This phone number be required membership registration',
    enter_your_name: 'Enter your name',
    choose_gender: 'Choose gender',
    invalid_email: 'Invalid email',
    enter_your_email: 'Enter your email',
    enter_your_address: 'Enter your address',
    enter_your_id_card: 'Enter your id card',
    the_brand_currently_does_not_support_membership_register: 'The brand currently does not support membership register',
    brand_does_not_enable_this_feature: 'The brand is not suppot this feature',
    system_error: 'System error',
    error_undefined: 'Error undefined',
    membership_card: 'Thẻ thành viên',
    voucher: 'Voucher',
    from_date: 'From',
    to_date: 'To',
    view_more: 'View more',
    collapse: 'Collapse',
    view_all: 'View all',
    register_now: 'Register now',
    free: 'Free',
    point: 'Point(s)',
    referral_code: 'referral code',
    enter_your_referral_code: 'Enter your referral code',
    referral_code_was_not_exist: 'The referral code was not exist',
    select_date: 'Select date',
    cancel: 'Cancel',
    confirm: 'Confrim',
    province: 'Province',
    select_province: 'Select province',
    district: 'District',
    select_district: 'Select district',
    cannot_enter_your_own_code: 'Cannot enter your own code',
}