import { localAppStorageHelper } from '../helpers/localAppStorageHelper';
// import { appRoutes } from '../navigators/appRoutes';
// import I18n from 'i18n-js';
// import { toastHelper } from '../helpers/toastHelper';
// import { stores } from '../stores';
import { appConfig } from '../appConfig';
import { alertHelper } from '../helpers/toastHelper';
import { logger } from '../helpers/logger';
import { STATUS_CODE } from '../constant';

class Http {

    async get(url: string, headers: any) {
        url = this.handelUrl(url);
        if (!headers) {
            headers = this.getUnAuthorizedHeader();
        }
        try {
            let response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            let responseJson = await response.json();
            this.handleResponse(responseJson);
            return responseJson;
        } catch (error) {
            this.handleCatchResponse(error);
            return error;
        }
    }

    async post(url: string, body: any, headers: any) {
        url = this.handelUrl(url);
        if (!headers) {
            headers = this.getUnAuthorizedHeader();
        }
        try {
            let response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            });
            let responseJson = await response.json();
            this.handleResponse(responseJson);
            return responseJson;
        } catch (error) {
            this.handleCatchResponse(error);
            return error;
        }
    }

    async put(url: string, body: any, headers: any) {
        url = this.handelUrl(url);
        if (!headers) {
            headers = this.getUnAuthorizedHeader();
        }
        try {
            let response = await fetch(url, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(body)
            });
            let responseJson = await response.json();
            this.handleResponse(responseJson);
            return responseJson;
        } catch (error) {
            this.handleCatchResponse(error);
            return error;
        }
    }

    async delete(url: string, headers: any) {
        url = this.handelUrl(url);
        if (!headers) {
            headers = this.getUnAuthorizedHeader();
        }
        try {
            let response = await fetch(url, {
                method: 'DELETE',
                headers: headers,
            });
            let responseJson = await response.json();
            this.handleResponse(responseJson);
            return responseJson;
        } catch (error) {
            this.handleCatchResponse(error);
            return error;
        }
    }


    private handelUrl(url: string) {
        if (!url.includes('http')) {
            url = (url[0] === '/') ? `${appConfig.config.apiDomain}${url}` : `${appConfig.config.apiDomain}/${url}`;
        }
        return url;
    }

    getUnAuthorizedHeader() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        return headers;
    }

    async getAuthorizedHeader() {
        const accessToken = await localAppStorageHelper.getAccessToken();
        const authorization = accessToken;
        const headers: any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authorization) {
            headers.Authorization = authorization;

        }
        return headers;
    }

    /**
     * 
     * @param object : example {
        parameter1: 'value_1',
        parameter2: 'value 2',
        parameter3: 'value&3' 
        }
    * The result: "parameter1=value_1&parameter2=value%202&parameter3=value%263"
    * 
     */

    objectToQueryParams(object: Object) {
        const esc = encodeURIComponent;
        const query = Object.keys(object)
            .map(k => `${esc(k)}=${esc((object as any)[k])}`)
            .join('&');
        return query;

    }

    private handleCatchResponse = async (err: any) => {
        logger.debug('Handle Catch Response', err);
        if (err?.message?.includes('Network request failed')) {
            // toastHelper.error(I18n.t('connection_error'));
        }
    }

    private handleResponse = async (response: any) => {
        logger.debug('Handle Respone', response);
        if (response.status_code === STATUS_CODE.NOT_ENABLE) {
            alertHelper.error('brand_does_not_enable_this_feature');
        } else if (response.status === 403) {
            // alertHelper.error('login_session_expired');
        } else if (response.status === 500 || response.status === 400) {
            alertHelper.error('error_undefined');
        } else if (response.status === 404) {
            alertHelper.error('system_error');
        }
    }

    private logout = async () => {
        await localAppStorageHelper.clear();
        // stores.navigation?.navigate(appRoutes.authNavigator);
    }
}

export const http = new Http();