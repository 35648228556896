import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './brand.scss';
import { useTranslation } from 'react-i18next';
import { BrandStore, BrandStoreClass } from './brandStore';
import { Route, Switch, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import { MONEY_UNITS, VND } from '../../../constant';
import { helper } from '../../../helpers/helper';

declare const window: any;

let brandStore: BrandStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    brandStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    overflow: 'auto',
    maxHeight: '100%',
  }
};
Modal.setAppElement('#root');

const RenderBrand = () => {
  const { t } = useTranslation();
  brandStore = useContext(BrandStore);
  brandStore.query = new URLSearchParams(useLocation().search);
  brandStore.t = t;

  return (
    <div className="sub-container">
      <div className="brand-container pb-4">

        {
          brandStore?.brand?.id
            ?
            <div>
              <div className="brand-banner" style={{ backgroundImage: `url(${brandStore.brand?.urlBanner || require('../../../assets/imgs/gift.png')})` }}>
              </div>
              <div className="brand-body">
                <div className="brand-info-container px-3 py-2 d-flex">
                  <div className="brand-info-sub-container">
                    <div className="p-relative">
                      <img className="avatar" src={brandStore.brand?.urlAvatar} alt="" />
                      <img className="verified" src={require('../../../assets/imgs/verified.png')} alt="" />
                    </div>
                    <div className="brand-title">
                      <h4 className="mb-0">{brandStore.brand?.name}</h4>
                      <div>{brandStore.brand?.category?.name}</div>
                    </div>
                  </div>
                </div>

                <div className=" btn-features-container d-flex justify-content-around align-items-center py-1">
                  <div className="brand-features-btn-item" onClick={() => brandStore.onClickMembershipCardBtn()}>
                    <div className="img-container">
                      <img className="brand-features-icon" src={require('../../../assets/imgs/loyalty_card.png')} alt="" />
                    </div>
                    <div>{t('membership_card')}</div>
                  </div>
                  <div className="brand-features-btn-item" onClick={() => brandStore.onClickMembershipRegister()}>
                    <div className="img-container">
                      <img className="brand-features-icon" src={require('../../../assets/imgs/dangkytv.png')} alt="" />
                    </div>
                    <div>{t('membership_register')}</div>
                  </div>
                </div>

                <div className="voucher-container">
                  <div className="voucher-title p-1">
                    {t('voucher')}:
                  </div>
                  <div className="voucher-body p-1">
                    {
                      (() => {
                        let res: any[] = [];
                        brandStore.vouchers.forEach((voucher, index) => {
                          (brandStore.isViewAllVoucher
                            || !brandStore.isViewAllVoucher && index < 6) && res.push(
                              <div className="d-flex voucher-item-container" key={voucher.id}
                                onClick={() => brandStore.onClickVoucher(voucher)}>
                                <div className="voucher-img-item-container" style={{ backgroundImage: `url(${voucher.image || require('../../../assets/imgs/gift.png')})` }}>
                                  {/* <img className="voucher-img-item" src={} alt="" /> */}
                                  {
                                    voucher.unit == MONEY_UNITS.VND
                                    && (voucher.value || 0) > 0
                                    &&
                                    <div className="voucher-value-container">
                                      <NumberFormat
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        value={voucher.value || 0}
                                      />
                                      <span>{VND}</span>
                                    </div>
                                  }
                                  {
                                    voucher.unit == MONEY_UNITS.PERCENT
                                    && (voucher.value || 0) > 0
                                    &&
                                    <div className="voucher-value-container">
                                      <span >{helper.getPercentNumber(voucher.value || 0)} </span>
                                      {/* {
                                        (voucher.limitAmount || 0) > 0
                                        &&
                                        <div>
                                          <span>({t('max')}:</span>
                                          <NumberFormat value={voucher.limitAmount || 0}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={(value: any) =>
                                              <span>{value} {VND})</span>
                                            } />
                                        </div>
                                      } */}
                                    </div>

                                  }
                                  <img className="voucher-brand-avatar" src={brandStore.brand?.urlAvatar} alt="" />
                                </div>
                                <div className="flex-1">
                                  <h6 className="mb-0"><span className="text-primary font-weight-bold">[{brandStore.brand?.name}]</span> {voucher.title}</h6>
                                  {
                                    (voucher?.expDate || voucher?.startDate) &&
                                    <div className="mt-2 text-secondary">
                                      <small >{brandStore.getExpiredDateFormat(voucher)}</small>
                                    </div>
                                  }
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                      <small>
                                        <i className="fa fa-shopping-cart"></i>
                                        <small className="ml-1">{voucher.quantityCodeGot}</small>
                                      </small>
                                      <small className="ml-1">
                                        <i className="fa fa-eye"></i>
                                        <small className="ml-1">{voucher.view}</small>
                                      </small>
                                    </div>
                                    <div className="price d-flex">
                                      {
                                        voucher.price > 0
                                        && <NumberFormat className="" value={voucher.price || 0} displayType={'text'} thousandSeparator={true} suffix={VND} />
                                      }
                                      {
                                        voucher.price > 0 && voucher.pricePoint > 0
                                        && <span className="mx-1">|</span>
                                      }
                                      {
                                        voucher.pricePoint > 0
                                        && <div>
                                          <NumberFormat className="" value={voucher.pricePoint || 0} displayType={'text'} thousandSeparator={true} suffix={''} />
                                          {
                                            voucher.brand?.brandPointCode ?
                                              <span className="ml-1">{voucher.brand?.brandPointCode}</span>
                                              :
                                              <span className="ml-1">{t('point')}</span>
                                          }
                                        </div>
                                      }
                                      {
                                        !(voucher.price > 0 || voucher.pricePoint > 0)
                                        && <span className="font-weight-normal">{t('free')}</span>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                        })
                        return res;
                      })()
                    }
                    {
                      brandStore.vouchers?.length > 6 &&
                      <div className="text-right font-weight-bold">
                        <h6 className="text-primary w-fitcontent ml-auto p-2 clickable"
                          onClick={() => brandStore.isViewAllVoucher = !brandStore.isViewAllVoucher}>

                          {
                            !brandStore.isViewAllVoucher
                            &&
                            <span>{t('view_all')}<i className="fa fa-angle-double-right ml-1"></i></span>
                          }
                          {
                            brandStore.isViewAllVoucher
                            &&
                            <span>
                              {t('collapse')}<i className="fa fa-angle-double-up ml-1"></i>
                            </span>
                          }
                        </h6>
                      </div>
                    }
                  </div>
                </div>

              </div>
            </div>
            : <div>
              {/* // no brand found */}
            </div>
        }


      </div>
    </div >

  );
};

const ObserverBrand = observer(RenderBrand);
export const BrandScreen = () => { return (<Wrapper> <ObserverBrand /> </Wrapper >) };
