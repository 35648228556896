import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './layout.scss';
import { useTranslation } from 'react-i18next';
import { LayoutStore, LayoutStoreClass } from './layoutStore';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logoDownload from '../../assets/imgs/loyal_one@2x.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GlobalStore } from '../../globalStore';
import { LOYA_ONE, URI } from '../../constant';
import { BrandScreen } from '../loyalty/brand/brand';
import { MembershipRegisterScreen } from '../loyalty/membershipRegister/membershipRegister';
import { RedirectScreen } from '../loyalty/redirect/redirect';
import { BrandMemberScreen } from '../loyalty/brandMember/brandMember';
import { VersionScreen } from '../version/version';

declare const window: any;

let layoutStore: LayoutStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    layoutStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    overflow: 'auto',
    maxHeight: '100%',
  }
};
Modal.setAppElement('#root');

const RenderLayout = () => {
  const { t } = useTranslation();
  layoutStore = useContext(LayoutStore);
  layoutStore.query = new URLSearchParams(useLocation().search);
  GlobalStore.layoutStore = layoutStore as any;

  return (
    <div >
      <div className="download-app-container d-flex justify-content-center p-1" onClick={() => layoutStore.onClickDownload()}>
        <div className="mr-1 d-flex">
          <div>
            <img className="download-logo" src={logoDownload} />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            {/* <div className="download-title">{LOYA_ONE}</div> */}
            <div className="download-description">{t('download_app_description')}</div>
            <div
              className="download-button btn btn-warning"
            >
              <div>{t('download_app')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding-header-content">
        <Switch>
          <Route path={URI.BRAND}>
            <BrandScreen />
          </Route>
          <Route path={URI.MEMBERSHIP_REGISTER}>
            <MembershipRegisterScreen />
          </Route>
          <Route path={URI.BRAND_MEMBERSHIP_INFO}>
            <BrandMemberScreen />
          </Route>
          <Route path={URI.REDIRECT}>
            <RedirectScreen />
          </Route>
          <Route path={URI.VERSION}>
            <VersionScreen />
          </Route>
          <Route path="*" component={RedirectScreen} />
        </Switch>
      </div>

    </div>
  );
};

const ObserverLayout = observer(RenderLayout);
export const LayoutScreen = () => { return (<Wrapper> <ObserverLayout /> </Wrapper >) };
