import { computed, observable } from "mobx";
import { createContext } from "react";
import { loyaltyApi } from "../../../api/loyalty.api";
import { BRAND_STATUS, STATUS_CODE, URI, zeroUID } from "../../../constant";
import { globalService } from "../../../globalService";
import { GlobalStore } from "../../../globalStore";
import { Brand } from "../../../models/brand";
import * as _ from 'lodash';
import { Voucher } from "../../../models/voucher";
import { createTransformer } from "mobx-utils/lib/create-transformer";
import { timeHelper } from "../../../helpers/timeHelper";
import { stores } from "../../../store";
import { appConfig } from "../../../appConfig";

declare const window: any;

export class BrandStoreClass {

  @observable query = new URLSearchParams();
  @observable brandId = '';
  @observable ref = '';
  @observable brand: Brand = {} as any;
  @observable isLoadingVoucher = false;
  @observable vouchers = [] as Voucher[];
  @observable t: any;
  @observable isViewAllVoucher = false;

  constructor() {
  }

  init = async () => {
    if (this.query.get('id')) {
      this.brandId = this.query.get('id') || '';
    }
    if (this.query.get('ref')) {
      this.ref = this.query.get('ref') || '';
    }
    const forceUpdate = this.query.get('true') || '';

    this.brand = await globalService.getCurrentBrand(this.brandId, !!forceUpdate);
    if (this.brand?.id) {
      // if (this.brand && this.brand.registerMemberTypes && this.brand.registerMemberTypes.length > 0) {
      // }

      this.getBrandVouchers();
    }

  }

  private getBrandVouchers = async () => {
    this.isLoadingVoucher = true;
    const response = await loyaltyApi.getBrandVouchers(this.brandId, 0, 30);
    if (response.status_code == STATUS_CODE.SUCCESS) {
      this.isLoadingVoucher = false;
      const onlyBrandVouchers = _.remove(response.data.items, (voucher: Voucher) => {
        return voucher?.brandId != zeroUID;
      })
      this.vouchers = onlyBrandVouchers;
    }
    this.isLoadingVoucher = false;
  }

  @computed get getExpiredDateFormat() {
    return createTransformer((voucher: Voucher) => {
      let date = '';
      if (voucher) {
        // if (voucher.startDate) {
        //   date += `${this.t('from_date')}  ${timeHelper.convertTimestampToDayMonthYear(voucher.startDate)} `;
        // }

        if (voucher.expDate) {
          date += ` ${this.t('to_date')}  ${timeHelper.convertTimestampToDayMonthYear(voucher.expDate)} `;
        }
      }
      return date;
    });
  }

  onClickMembershipRegister() {
    stores.history.push({
      search: `id=${this.brandId}&ref=${this.ref}`,
      pathname: URI.MEMBERSHIP_REGISTER,
    });
  }

  onClickMembershipCardBtn() {
    stores.history.push({
      search: `id=${this.brandId}&ref=${this.ref}`,
      pathname: URI.BRAND_MEMBERSHIP_INFO,
    });
  }

  onClickVoucher(voucher: Voucher) {
    location.href = appConfig.config.webVoucher + `/share/?id=${voucher.id}`;
  }

}

export const BrandStore = createContext(new BrandStoreClass());
