import { createContext } from "react";
import { toast } from "react-toastify";
import { EVENT_EMITTER, URI } from "./constant";
import { globalEvent } from "./helpers/globalEvents";
import { observable } from "mobx";
import { LocalStorageKeyEnum } from "./helpers/localAppStorageHelper";
import { logger } from "./helpers/logger";
import { stores } from "./store";

const DEFAULT_TOASTR_DURATION = 5000;
class AppStore {

    @observable t: any;
    @observable loading = false;

    constructor() {

        // loading
        globalEvent.loadingAll.addListener(EVENT_EMITTER.LOADING_ALL, ((show: boolean) => {
            this.loading = show;
        }));

        // alert
        globalEvent.toastr.addListener(EVENT_EMITTER.TOASTR, (obj: { type: string, text: string }) => {
            const textTranslated = this.t(obj?.text || '');
            switch (obj?.type) {
                case 'success':
                    this.success(textTranslated);
                    break;
                case 'error':
                    this.error(textTranslated);
                    break;
                case 'info':
                    this.info(textTranslated);
                    break;
                case 'warning':
                    this.warning(textTranslated);
                    break;
            }
        });
    }


    success = (txt: string) => {
        toast.success(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }
    info = (txt: string) => {
        toast.info(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }
    warning = (txt: string) => {
        toast.warning(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }
    error = (txt: string) => {
        toast.error(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }



}

export default createContext(new AppStore());
