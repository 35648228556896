import { authApi } from "./api/auth.api";
import { http } from "./api/http";
import { appConfig, config } from "./appConfig";
import { DEVICE, EVENT_EMITTER, SOURCE_TYPE, STATUS_CODE } from "./constant";
import { globalEvent } from "./helpers/globalEvents";
import { helper } from "./helpers/helper";
import { localAppStorageHelper } from "./helpers/localAppStorageHelper";
import { logger } from "./helpers/logger";
import "clientjs";
import { loyaltyApi } from "./api/loyalty.api";

// "this library was built to include directly into the browser, not imported."
// @ts-ignore
const client = new ClientJS();

class GlobalService {
    getCurrentBrand = async (brandId?: string, force = false) => {
        let brand;
        brand = await localAppStorageHelper.getBrand();
        if (brand?.id && !force) {
            return brand;
        } else {
            try {
                if (brandId) {
                    const res = await authApi.getBrandById(brandId || '');
                    if (res.status_code === STATUS_CODE.SUCCESS) {
                        brand = res.data;
                        logger.debug(brand);
                    } else {
                        throw res;
                    }
                }
            } catch (err) {
                helper.processCommonError(err);
            }
            if (brand?.id) {
                return brand;
            } else {
                // todo: process when no brand selected
                return brand;
            }
        }
    }

    onClickDownload = async (params = { isIgnoreOpenStore: false, loading: false }) => {
        try {
            globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, params?.loading);
            let device = await helper.getDevice();
            const query = this.getObjectParams();

            if (device == DEVICE.Android) {
                await this.openMobileApp();
                if ((query['bizApp'] + '') == 'true') {
                    if (query['a'] == 'forgot_password') {
                        this.openWebNewPasswordBiz(true);
                    }
                } else {
                    if (!params?.isIgnoreOpenStore) {
                        await this.openGooglePlayStore();
                    }
                }
            } else if (device == DEVICE.iPhone || device == DEVICE.iOS) {
                await this.openMobileApp();
                if ((query['bizApp'] + '') == 'true') {
                    this.openWebNewPasswordBiz(true);
                } else {
                    if (!params?.isIgnoreOpenStore) {
                        await this.openAppStore();
                    }
                }
            } else {
                if ((appConfig.config.name == config.development.name || appConfig.config.name == config.staging.name)
                    && (!process.env.NODE_ENV || process.env.NODE_ENV == 'development')
                ) {
                    logger.debug('DEVELOPING: PREVENT REDIRECT TO APPSTORE ON DEVELOPMENT');
                } else {
                    if ((query['bizApp'] + '') == 'true') {
                        if (query['a'] == 'forgot_password') {
                            this.openWebNewPasswordBiz();
                        }
                    } else {
                        if (!params?.isIgnoreOpenStore) {
                            window.location.href = appConfig.config.appStoreLink;
                        }
                    }
                }
            }
        } catch (err) {
            console.log('loi: ' + err)
        } finally {
            globalEvent.loadingAll.emit(EVENT_EMITTER.LOADING_ALL, false);
        }
    }

    openGooglePlayStore() {
        return new Promise(resolve => {
            setTimeout(() => {
                window.location.href = appConfig.config.androidGooglePlayLink;
                resolve();
            }, 2000);
        });
    }

    openAppStore() {
        return new Promise(resolve => {
            setTimeout(() => {
                window.location.href = appConfig.config.appStoreLink;
                resolve();
            }, 4000);
            // var vis = (function () {
            //     var stateKey: any, eventKey: any, keys: any = {
            //         hidden: "visibilitychange",
            //         webkitHidden: "webkitvisibilitychange",
            //         mozHidden: "mozvisibilitychange",
            //         msHidden: "msvisibilitychange"
            //     };
            //     for (stateKey in keys) {
            //         if (stateKey in document) {
            //             eventKey = keys[stateKey];
            //             break;
            //         }
            //     }
            //     return function (c: any) {
            //         if (c) document.addEventListener(eventKey, c);
            //         return !(document as any)[stateKey];
            //     }
            // })();
            // vis(() => {
            //     console.log('inactive tab');
            //     clearTimeout(timeout);
            // })
        });
    }

    openWebNewPasswordBiz(wait = false) {
        let time = wait ? 2000 : 0;
        setTimeout(() => {
            const query = this.getObjectParams();
            const params = new URLSearchParams(query).toString()
            window.location.href = appConfig.config.authBizLink + '/auth/new-password?' + params;
        }, time);
    }
    /**
     * called from 
     * 1. this
     * 2. redirectStore
     *  */
    openMobileApp() {
        return new Promise(async (resolve) => {
            setTimeout(async () => {
                try {
                    // download from share web voucher => Voucher: type=share,a=voucher,id=voucherId
                    // download from gift web voucher => Home: type=gift
                    // download app from userweb => brand: (here) id=brandId,
                    // scan brand qr shop => brand: type=brandInfo,a=all,id=brandId
                    // download app from e-web => e-web: type=branch,a=shopping,id=brandId,sid=locationId
                    // register from e-web => Home: type=newLOAccount no params

                    // scan qr voucher from user => Home:  a=v,code=voucherCode => shopapp scan => dont process

                    const query = this.getObjectParams();
                    try {
                        logger.debug('shop app', query, JSON.stringify(query));
                    } catch (err) {
                        console.log(err);
                    }
                    if ((query['bizApp'] + '') == 'true') {
                        // shop app
                        const params = this.convertObjToStringParamsForMobileApp(query);
                        const ressultLink = appConfig.config.deepLinkBizApp + params;
                        logger.debug('Link send to mobile: ', ressultLink)
                        location.href = ressultLink; // 'loyal-one-biz://';//
                    } else {
                        // user app
                        const type = query['type'];

                        if (type == SOURCE_TYPE.SHARE) {
                            query['a'] = 'voucher';
                        } else if (type == SOURCE_TYPE.GIFT) {
                            query['a'] = '';
                        } else {
                            if (type != 'shopping'
                                || type != 'newLOAccount'
                                || type != 'branch') {
                                query['a'] = 'all';
                            }
                        }

                        // incase open register on mobile app
                        if (query['ref']) {
                            query['a'] = 'registerMember';
                            query['sid'] = query['ref'];
                        }
                        const params = this.convertObjToStringParamsForMobileApp(query);
                        // send link to BE before open userappp
                        const branchCode = query['branchCode'];
                        if (branchCode && branchCode.length > 0) {   
                            const branchLink = appConfig.config.deepLinkIntoBranch + branchCode;
                            await this.sendDeepLinkToServer(branchLink);
                            logger.debug('Link send to mobile: ', branchLink);
                            window.location.href = branchLink;
                        } else {
                            const ressultLink = appConfig.config.deepLink + params;
                            await this.sendDeepLinkToServer(ressultLink);
                            logger.debug('Link send to mobile: ', ressultLink);
                            window.location.href = ressultLink;
                        }
                    }
                } catch (err) { }
                resolve();
            }, 100);
        });
    }

    sendDeepLinkToServer(params: string) {
        // copy deeplink to clipboard
        // just copy text when user interact with browser
        helper.copyToClipboardByText(params);

        return new Promise(async (resolve) => {
            try {

                const isMobile = client.isMobile();

                if (isMobile) {
                    // Get the client's fingerprint id
                    const timezone = - new Date().getTimezoneOffset() / 60;
                    const os = client.getOS();
                    const osVersion = client.getOSVersion();
                    const deviceVendor = client.getDeviceVendor();

                    const body = {
                        timezone: timezone,
                        os: os,
                        osVersion: osVersion,
                        deviceVendor: deviceVendor,
                        deepLink: params,
                    };
                    logger.debug('SendDeepLinkToServer -> body', body);
                    // alert(JSON.stringify(body));
                    try {
                        const res = await loyaltyApi.addDeeplinkDataForUserApp(body);
                        logger.debug(res);
                        // if (res.status_code === STATUS_CODE.SUCCESS) {
                        // } else {
                        //     throw res;
                        // }
                    } catch (err) {
                        helper.processCommonError(err);
                    }
                }
            } catch (err) {
                logger.debug(err);
            }
            resolve();
        });
    }


    getObjectParams() {
        let searchParams = location.search;
        // remove question for open app receive params on app
        if (searchParams && searchParams.charAt(0) == '?') {
            searchParams = searchParams.substring(1, searchParams.length);
        }
        let query = helper.convertFullPathToQuery(searchParams);
        return query;
    }

    convertObjToStringParamsForMobileApp(obj: any) {
        var str = "";
        for (var key in obj) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + obj[key];
        }
        return str;
    }
}
export const globalService = new GlobalService();