import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import AppStore from "./AppStore";
import { URI } from "./constant";
import { appConfig, config } from "./appConfig";
import { logger } from "./helpers/logger";
import loadingIcon from './assets/imgs/ajax-loader.gif';
import { observer } from "mobx-react-lite";
import { RouterHistory } from "./screens/auth/routerHistory/routerHistory";
import { LayoutScreen } from "./screens/layout/layout";
import { MembershipRegisterScreen } from "./screens/loyalty/membershipRegister/membershipRegister";
import { BrandScreen } from "./screens/loyalty/brand/brand";



if (appConfig.config.name == config.production.name
  && !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  if (window) {
    if (localStorage.getItem('dev') === 'true') {
      console.log('Dev mode is enable');
    } else {
      const emptyFunc = function () { };
      window.console.log = emptyFunc;
      window.console.warn = emptyFunc;
      window.console.info = emptyFunc;
      window.console.error = emptyFunc;
      logger.log = emptyFunc;
      logger.debug = emptyFunc;
      logger.warn = emptyFunc;
      logger.info = emptyFunc;
      logger.error = emptyFunc;
    }
  }
}

const App = () => {
  const { t, i18n } = useTranslation();
  const appStore = useContext(AppStore);
  appStore.t = t;

  return (
    <div>
      <div className="body">
        <div className='layout-wrapper'>
          <div className='layout-container'>
            {
              appStore.loading &&
              <div className="loading-whole-page">
                <img src={loadingIcon} width="70" />
              </div>
            }
            <div>

              {/* Please use route in layoutScren */}
              <Router>
                <RouterHistory></RouterHistory>
                <LayoutScreen />
                {/* <Route path="*" component={LayoutScreen} /> */}
              </Router>
            </div>
          </div>
          <ToastContainer />

        </div>
      </div>
    </div>
  );
};

export default observer(App);
