import { Brand } from "../models/brand";

export enum LocalStorageKeyEnum {
    accessToken = 'AT',
    account = 'A',
    logoutBroadcast = 'lbc',
    brand = 'b',
}

class LocalAppStorageHelper {

    private async set(key: LocalStorageKeyEnum, value: any) {
        await localStorage.setItem(key, JSON.stringify(value));
    }

    private async get(key: LocalStorageKeyEnum) {
        const value = await localStorage.getItem(key);
        if (value) {
            try {
                return JSON.parse(value);
            } catch {
                return null
            }
        } else {
            return null;
        }
    }

    async delete(key: LocalStorageKeyEnum) {
        await localStorage.removeItem(key);
    }

    async clear() {
        for (let key in LocalStorageKeyEnum) {
            await localStorage.removeItem((LocalStorageKeyEnum as any)[key]);
        }
    }

    async setBrand(brand: Brand) {
        await this.set(LocalStorageKeyEnum.brand, brand);
    }

    async getBrand(): Promise<Brand> {
        return await this.get(LocalStorageKeyEnum.brand);
    }

    async getAccessToken(): Promise<string> {
        return await this.get(LocalStorageKeyEnum.accessToken);
    }

    async setAccessToken(accessToken: string) {
        await this.set(LocalStorageKeyEnum.accessToken, accessToken);
    }

    async getAccount(): Promise<Account> {
        return await this.get(LocalStorageKeyEnum.account);
    }

    async setAccount(account: Account) {
        await this.set(LocalStorageKeyEnum.account, account);
    }

    async getLogoutBroadCast(): Promise<any> {
        return await this.get(LocalStorageKeyEnum.logoutBroadcast);
    }

    async setLogoutBroadCast(data: any) {
        await this.set(LocalStorageKeyEnum.logoutBroadcast, data);
    }

}

export const localAppStorageHelper = new LocalAppStorageHelper();