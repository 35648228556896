
import { observable } from 'mobx';
const appVersion = '1.1.1';

export const config = {
    development: {
        name: 'development',
        appVersion: appVersion,
        apiDomain: 'https://development.loyaworld.com:4001',
        // loyaOneBizLink: 'http://localhost:4600/#/auth/login',
        // loyaWorldBizLink: 'http://localhost:4300/#/auth/login',
        loyaOneBizLink: 'https://loyaonepartnerdev.firebaseapp.com/#/auth/login',
        loyaWorldBizLink: 'https://loyawebshop.firebaseapp.com/#/auth/login',

        androidGooglePlayLink: 'https://play.google.com/store/apps/details?id=loyal.one.user.mobile',
        appStoreLink: 'https://apps.apple.com/us/app/loyal-one/id1495752098',
        websiteLink: 'https://loya.one/',
        deepLink: 'loyal-one-user-mobile://',
        deepLinkBizApp: 'loyal-one-biz://',
        authBizLink: 'https://loyaoneauth.web.app',
        webVoucher: 'http://development.loyaworld.com:4087',
        deepLinkIntoBranch: 'loyal-one-user-mobile://a=shopping&id=',
    },
    staging: {
        name: 'staging',
        appVersion: appVersion,
        apiDomain: 'https://staging.loyaworld.com:4001',
        loyaOneBizLink: 'https://staging.loyaworld.com:89/#/auth/login',
        loyaWorldBizLink: 'https://staging.loyaworld.com:81/#/auth/login',

        androidGooglePlayLink: 'https://play.google.com/store/apps/details?id=loyal.one.user.mobile',
        appStoreLink: 'https://apps.apple.com/us/app/loyal-one/id1495752098',
        websiteLink: 'https://loya.one/',
        deepLink: 'loyal-one-user-mobile://',
        deepLinkBizApp: 'loyal-one-biz://',
        authBizLink: 'https://staging.loyaworld.com:86',
        webVoucher: 'http://staging.loyaworld.com:4087',
        deepLinkIntoBranch: 'loyal-one-user-mobile://a=shopping&id=',
    },
    production: {
        name: 'production',
        appVersion: appVersion,
        apiDomain: 'https://api.loya.one:4001',
        loyaOneBizLink: 'https://biz.loya.one/#/auth/login',
        loyaWorldBizLink: 'https://biz.loyaworld.com/#/auth/login',

        androidGooglePlayLink: 'https://play.google.com/store/apps/details?id=loyal.one.user.mobile',
        appStoreLink: 'https://apps.apple.com/us/app/loyal-one/id1495752098',
        websiteLink: 'https://loya.one/',
        deepLink: 'loyal-one-user-mobile://',
        deepLinkBizApp: 'loyal-one-biz://',
        authBizLink: 'https://authbiz.loya.one',
        webVoucher: 'https://v.loya.one',
        deepLinkIntoBranch: 'loyal-one-user-mobile://a=shopping&id=',
    }
}

class AppConfig {

    @observable
    config = config.production;

    constructor() {
    }

}

export const appConfig = new AppConfig();

