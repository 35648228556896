import { HttpResponse } from "../models/httpResponse";
import { http } from "./http";
import { Brand } from "../models/brand";

class AuthApi {

    async getBrandById(id: string): Promise<HttpResponse<any>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.get('point/brand?detail=true&brandId=' + id, header);
        return response;
    }

    async registerMembership(data: any): Promise<HttpResponse<any>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.post('point/brand/registerMember', data, header);
        return response;
    }
}
export const authApi = new AuthApi();